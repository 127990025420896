<script lang="ts" setup>
// Komponent odpowiedzialny za wyświetlanie okienka do akceptacji cookies

import { usePLKBreakpoints } from '~coreComposables/breakpoints.composable';

const { isMobile } = usePLKBreakpoints();

const { initialize } = useGtag();

const date = new Date();
date.setDate(date.getDate() + 365);

const cookiesAccepted = useCookie<boolean>('cookiesAccepted', {
  expires: date
});

onMounted(() => {
  if (cookiesAccepted.value) initialize();
});

function acceptCookie() {
  cookiesAccepted.value = true;

  initialize();
}
</script>

<template>
  <CdsAlert
    v-if="!cookiesAccepted"
    variant="solid"
    type="info"
    size="regular"
    bg-color="primary50"
    color="secondary00"
    :inline-actions="!isMobile"
  >
    <div class="text-left">
      {{ $t('cookies.message') }}
      <NuxtLink
        :to="{ name: $routeNames.privacyPolicy }"
        class="anchor-underline anchor-underline--always"
        >{{ $t('cookies.messageLink') }}</NuxtLink
      >.
    </div>

    <template #actions>
      <CdsBtn
        variant="text"
        size="regular"
        color="surface00"
        @click="acceptCookie"
      >
        {{ $t('cookies.accept') }}
      </CdsBtn>
    </template>
  </CdsAlert>
</template>
